import { Injectable } from '@angular/core';
import { RestApiService } from '../shared/services/rest-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private restApiService: RestApiService) { }

  public deleteNotification(selectedId:any): Observable<any>{
    return this.restApiService.delete('Delete Data' , `/api/secure/homelocal/notifications.json?notificationId=${selectedId}`,'page-center')
  }

  public getCityListByZip(zipCode: any): Observable<any>{
    return this.restApiService.get('city list', `/api/metadata/zip-details.json?zipCode=${zipCode}`, 'page-center');
  }

  public getStateList(country: any): Observable<any>{
    return this.restApiService.get('state list', `/ajax/secure/zip/stateList.json?countryName=${country}`, 'page-center');
  }

  public getCityList(country: any, state: any, county: any): Observable<any>{
    return this.restApiService.get('city list', `/ajax/secure/zip/cityList.json?countryName=${country}&stateName=${state}&countyName=${county}`);
  }

  public getCityListWithoutCounty(country: any, state: any): Observable<any>{
    return this.restApiService.get('city list', `/ajax/secure/zip/cityList.json?countryName=${country}&stateName=${state}`, 'page-center');
  }

  public getCountyList(country: any, state: any): Observable<any>{
    return this.restApiService.get('county list', `/ajax/secure/zip/countyList.json?countryName=${country}&stateName=${state}`, 'page-center');
  }
  public getCountryList(country: any): Observable<any>{
    return this.restApiService.get('country list', `/ajax/secure/zip/countryList.json?countryName=${country}`, 'page-center');
  }

  public getPushToPage(): Observable<any>{
    return this.restApiService.get('Push To Page list', `/api/secure/homelocal/notifications/get-notification-types.json?`, 'page-center');
  }

  public getTags(): Observable<any>{
    return this.restApiService.get('Search list', `/api/secure/tags/list.json?search=&offset=0&limit=100&sortColumn=title&sortType=desc`, 'page-center');
  }

  public getMembersInfo():Observable<any>{
    return this.restApiService.get('Member Info',`/api/secure/homelocal/members/list.json?search=&recordStatus=A&offset=0&limit=200&sortColumn=&sortType=asc`,'page-center')
  }

  saveNotificationDetails(data:any) :Observable<any>{
    return this.restApiService.post('Add Notification Details' ,'/api/secure/homelocal/notifications/add.json?', data)
  }

  public viewNotification(id:any):Observable<any>{
    return this.restApiService.get('View Notification',`/api/secure/homelocal/notifications/edit.json?notificationId=${id}`,'page-center')
  }

  public MembersInfo(member:any , limit:any):Observable<any>{
    return this.restApiService.get('Member Info',`/api/secure/homelocal/members/list.json?search=${member}&recordStatus=A&offset=0&limit=${limit}&sortColumn=&sortType=asc`,'page-center')
  }



}
