<div #dataTable (window:resize)="onResize($event)" [class]="divClass" *ngIf="showSearchAndActionBtn">

  <div class="search-and-export-data-panel p-grid">

    <div *ngIf="searchBar == 'top'" [class]="searchbarClass" class="search-bar-div">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input class="search-input" type="text" pInputText [(ngModel)]="search" (keyup)=onQuickFilterChanged($event)
          [placeholder]="searchPlaceholder" />
      </span>

    </div>
    <div [class]="statusClass" class="dropdown-one" *ngIf="isStatusDropdown">
      <div class="dropdown-one-label" translate>lbl_status</div>
      <p-dropdown [options]="optionsForDropDownOne" [optionLabel]="'value'" [optionValue]="'id'"
        (onChange)="statusFilter($event)" [(ngModel)]="selectedStatus"> </p-dropdown>
    </div>



    <div class="action-button p-col-7">
      <p-button *ngIf="showExportBtn" class="export-btn" [label]="exportBtnLabel" (click)="exportGrid($event)">
        <img src="../../../../../assets/images/excel.png" class="excel-icon">
      </p-button>
      <p-button *ngIf="showActionBtn" [label]="buttonLabel" class="add-btn" (click)="actionBtnClick($event, 'add')">
        <img *ngIf="isAddMember" class="add-button-icon" src="../../../../../assets/images/add-member2.png">
      </p-button>

      <p-button *ngIf="exportData && isShowExport" label="Export Forms" class="export-btn export-worker" (click)="exportDialog()">
        <img class="add-button-icon excel-icon" src="assets/images/export.svg">
      </p-button>

    </div>

  </div>
</div>

<p-table #resetPage [lazy]="true" [rows]="paginationPageSize" [value]="datasource" [paginator]="pagination"
  [totalRecords]="totalRecords" (onLazyLoad)="loadDatasourceLazy($event)" [selectionMode]="rowSelection"
  (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" [(selection)]="selectedRecords"
  [rowsPerPageOptions]="rowsPerPage" [showFirstLastIcon]="false" class="p-col-12" responsiveLayout="scroll"  scrollHeight="flex" >


  <ng-template pTemplate="header">
    <tr>
      <ng-container *ngFor="let col of columnDefs">

        <th [width]="col.width" *ngIf="col.resize && enableResizer" pResizableColumn [pSortableColumn]="col.sort?col.field:null">
          {{col.header}}
          <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
        </th>

        <th *ngIf="!col.resize" [pSortableColumn]="col.sort ? col.field : null">
          {{col.header}}
          <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
        </th>
      </ng-container>
      <th style="width:50px" *ngIf="actionButtonsDefs.length"></th>


    </tr>
  </ng-template>

  <ng-template pTemplate="footer">
    <tr class="" [attr.colspan]="columnDefs.length">
      <!-- <td> -->
      <div class="no-records" *ngIf="totalRecords === 0">
        <div class="center-div" translate> lbl_no_records_found </div>
      </div>
      <!-- </td> -->
    </tr>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="pageinator" translate>lbl_items_per_page</div>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columnDefs" let-rowIndex="rowIndex">

    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [class]="rowIndex%2==0?'even-row':'odd-row'">
      <td *ngFor="let col of columnDefs" [class]="col.class">
        <!-- {{rowData[col.field]}} -->

        <div innerHTML="{{rowData[col.field]}}"></div>

      </td>

      <td *ngIf="actionButtonsDefs.length" class="action-icons" (click)="actionBtnClick($event, rowData)">
        <div class="action-icon">
          <div class="grid-menu" appClickOutside (clickOutside)="close($event)">
            <div *ngFor="let btn of actionButtonsDefs" class="grid-menu-item">
              <div [class]="btn.class">{{btn.label}}</div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>

</p-table>
<!-- <div class="pagination-records">Items per page</div> -->

<p-dialog [(visible)]="showExportData" [style]="{ width: '38vw' }"
  [closable]="true" [showHeader]="true" (onHide)="onDialogClose()" class="export-worker-data">
  <ng-template pTemplate="header">
    <div class="header-title">
      <div class="p-col-12 mb-10">
        <h2 class="header" translate>Export</h2>
      </div>
    </div>
  </ng-template>
  <div class="p-col-12 mb-10">
    <div class="form-label">
      <label translate>Signed Date Range</label>
    </div>  
    <span class="search-icon d-inline-flex w-full">
        <p-calendar
        #myCalendar
        [responsiveOptions]="[]"
        dateFormat="mm/dd/yy"
        class="pdate-cust calender"
        inputId="content-created-date"
        placeholder="{{ 'datePlaceholder' | translate }}"
        (onSelect)="onDateRangeClose($event)"
        selectionMode="range"
        [(ngModel)]="rangeDates"
        showIcon="true"
        appendTo="body"
        (ngModelChange)="onInputChange($event)"
        [maxDate]="toDate"
        (onFocus)="resetDateRange()"
        ></p-calendar>
        <span class="select-date-range-note">If no date is selected, all forms will be exported.</span>
        <span class="select-date-range"><span *ngIf="isDateError || endDateIsNotSelected">Please select the date range</span></span>
    </span>
  </div>

  <div class="p-col-12 mb-10">
    <div class="form-label">
      <label translate>Local</label>
    </div> 
    <span class="search-icon d-inline-flex w-full export-input-box">
        <input pInputText [value]="activeLocal" disabled />
    </span>
  </div>

  <div class="p-col-12 mb-10">
    <div class="form-label">
      <label translate>Worker Search</label>
    </div> 
    <span class="search-icon d-inline-flex w-full export-input-box">
        <!-- <input pInputText [(ngModel)]="workerSearch" placeholder="Enter Worker ID, First Name, Last Name, Email ID, Mobile Number" (ngModelChange)="onInputChange($event)" /> -->
        <p-multiSelect
          [options]="membersList"
          [style]="{ width: '100%', height: '33px' }"
          [(ngModel)]="selectedMembers"
          optionLabel="value"
          (onFilter)="filterMembers($event)"
          (ngModelChange)="onInputChange($event)"
          placeholder="Search by (Worker Id and Name)"
          appendTo="body"
        ></p-multiSelect>
    </span>
  </div>

  <div class="p-col-12 mb-10">
    <div class="form-label">
      <label translate>Form Type</label>
    </div> 
    <span class="search-icon d-inline-flex w-full">
        <p-dropdown
          [options]="formTypeList"
          [optionLabel]="'value'"
          [optionValue]="'id'"
          [(ngModel)]="formType"
          (ngModelChange)="onInputChange($event)"
          appendTo="body"
        >
        </p-dropdown>
    </span>
  </div>

  <div class="p-col-12 mb-10" *ngIf="userRole!=3">
      <div class="status-dropdown">
          <div class="dropdown-one-label form-label" translate>lbl_state</div>
          <span class="search-icon d-inline-flex w-full">
            <p-dropdown [options]="stateList" [(ngModel)]="selectedState" [optionLabel]="'value'"
              [optionValue]="'id'" (onChange)="onStateChange($event)" (ngModelChange)="onInputChange($event)" appendTo="body"></p-dropdown>
          </span>
      </div>
  </div>
  <div class="p-col-12 mb-10">
    <p class="response-message" *ngIf="responseMessage!=''" [innerHTML]="responseMessage"></p>
    <p class="response-message-error" *ngIf="responseErrorMessage!=''">{{responseErrorMessage}}</p>
  </div>
  <div class="p-col-12 mb-10 center-alignment">
    <button pButton *ngIf="!showExport" type="button" (click)="applyFiter()" class="apply-filter" translate>Apply Filters</button>
    <button pButton *ngIf="showExport" type="button" (click)="exportWorkerData()" class="export-data" translate>Export</button>
    <button pButton type="button" (click)="resetFilters()" translate>Reset Filters</button>
    <!-- <button pButton class="cancelBtn"  type="button" (click)="cancel()" translate>Cancel</button> -->
  </div>
</p-dialog>