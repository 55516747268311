import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SidebarService } from '../../services/sidebar.service';
import { StorageService } from '../../services/storage.service';
import { UserCommonService } from '../../services/user-common.service';
import { LoaderService } from '../loader/loader.service';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-super-admin-sidebar',
  templateUrl: './super-admin-sidebar.component.html',
  styleUrls: ['./super-admin-sidebar.component.scss']
})
export class SuperAdminSidebarComponent {
  subscription: Subscription;
  user;
  ilaLogoClass = 'ila-logo-shrink';
  collapsedItems: MenuItem[] = [];
  shrinkItems: MenuItem[] = [];
  onBoardingProcess: any;

  @Input() isCollapsed = false;

  constructor(
    private storageService: StorageService,
    private ms: MessageService,
    private sidebarService: SidebarService,
    private router: Router,
    private userCommonService: UserCommonService
  ) { }

  ngOnInit() {
    this.onBoardingProcess = JSON.parse(
      this.storageService.getItem(AppSettings.ONBOARDING_ACCESS)
    );
    this.initSidebarItems();
    this.initShrinkMenuItems();
  }

  public initSidebarItems() {
    this.collapsedItems = [
      {
        routerLink: ["dashboard"],
        icon: "dashboard-inactive",
        label: 'Dashboard',
        id: 'dashboard',
        expanded: true,
        command: (menu) => {
          this.collapsedItems.find(element => element.id === 'associationManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'pvwManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'companyManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'saftyTraining').expanded = false;
          this.collapsedItems.find(element => element.id === 'directory').expanded = false;
          this.collapsedItems.find(element => element.id === 'ourHistory').expanded = false;
          this.collapsedItems.find(element => element.id === 'dynamicGrids').expanded = false;
          this.collapsedItems.find(element => element.id === 'localManageMent').expanded = false;
          this.collapsedItems[0].expanded = true;
          this.shrinkItems[0].expanded = true;
        },
      },
      {
        routerLink: ["members"],
        icon: "onboarding-members-inactive",
        label: "Onboarding New Workers",
        id: "onboardingMembers",
        visible:this.onBoardingProcess === AppSettings.ACCESS_TO_ONBOARDING,
        command: (menu) => {
          this.collapsedItems.find(element => element.id === 'companyManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'associationManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'media').expanded = false;
          this.collapsedItems.find(element => element.id === 'saftyTraining').expanded = false;
          this.collapsedItems.find(element => element.id === 'directory').expanded = false;
          this.collapsedItems.find(element => element.id === 'ourHistory').expanded = false;
          this.collapsedItems.find(element => element.id === 'dynamicGrids').expanded = false;
          this.collapsedItems.find(element => element.id === 'localManageMent').expanded = false;
          this.collapsedItems[0].expanded = false;
          this.shrinkItems[0].expanded = false;
          this.shrinkItems[0].icon = 'dashboard-active';
          this.setPreviousMenuInactive(menu,'forCollapsedMenu');
        },
      },

      {
        icon: "app-users",
        label: 'App Users',
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/user/appUser.do`;
        },
      },
      {
        icon: "news-inactive",
        label: 'News',
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/news.do`;
        },
      },
      {
        icon: "breaking-news",
        label: `ILA Updates`,
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/pop-news.do`;
        },
      },
      {
        icon: "events-inactive",
        label: 'Events',
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/user/appUser.do`;
        },
      },
      {
        icon: "media-inactive",
        label: 'Media',
        id: 'media',
        command: (menu) => {
          this.collapsedItems.find(element => element.id === 'associationManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'pvwManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'companyManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'saftyTraining').expanded = false;
          this.collapsedItems.find(element => element.id === 'directory').expanded = false;
          this.collapsedItems.find(element => element.id === 'ourHistory').expanded = false;
          this.collapsedItems.find(element => element.id === 'dynamicGrids').expanded = false;
          this.collapsedItems.find(element => element.id === 'localManageMent').expanded = false;
          this.collapsedItems[0].expanded = false;
          this.shrinkItems[0].expanded = false;
          this.shrinkItems[0].icon = 'dashboard-active';
          this.setPreviousMenuInactive(menu,'forCollapsedMenu');
        },
        items: [
          {
            icon: "photo-inactive",
            label: 'Photos',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/media-photo.do`;
            },
          },
          {
            icon: "video-inactive",
            label: 'Videos',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/media-video.do`;
            },
          },
          {
            icon: "photo-inactive",
            label: 'App Images',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/media-app-images.do`;
            },
          }
        ],
      },
      {
        icon: "info-inactive",
        label: 'Safty/Training',
        id: 'saftyTraining',
        command: (menu) => {
          this.collapsedItems.find(element => element.id === 'associationManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'pvwManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'companyManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'media').expanded = false;
          this.collapsedItems.find(element => element.id === 'directory').expanded = false;
          this.collapsedItems.find(element => element.id === 'ourHistory').expanded = false;
          this.collapsedItems.find(element => element.id === 'dynamicGrids').expanded = false;
          this.collapsedItems.find(element => element.id === 'localManageMent').expanded = false;
          this.collapsedItems[0].expanded = false;
          this.shrinkItems[0].expanded = false;
          this.shrinkItems[0].icon = 'dashboard-active';
          this.setPreviousMenuInactive(menu,'forCollapsedMenu');
        },
        items: [
          {
            icon: "photo-inactive",
            label: 'Publication',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/safety-training/publications.do`;
            },
          },
          {
            icon: "video-inactive",
            label: 'Videos',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/safety-training/videos.do`;
            },
          },
          {
            icon: "info-inactive",
            label: 'Safty Tips',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/safety-training/monthly-safety-tip.do`;
            },
          }
        ],
      },
      {
        icon: 'directory-inactive',
        label: 'Directory',
        id: 'directory',
        command: (menu) => {
          this.collapsedItems.find(element => element.id === 'associationManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'pvwManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'companyManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'media').expanded = false;
          this.collapsedItems.find(element => element.id === 'saftyTraining').expanded = false;
          this.collapsedItems.find(element => element.id === 'ourHistory').expanded = false;
          this.collapsedItems.find(element => element.id === 'dynamicGrids').expanded = false;
          this.collapsedItems.find(element => element.id === 'localManageMent').expanded = false;
          this.collapsedItems[0].expanded = false;
          this.shrinkItems[0].expanded = false;
          this.shrinkItems[0].icon = 'dashboard-active';
          this.setPreviousMenuInactive(menu,'forCollapsedMenu');
        },
        items: [
          {
            icon: "international-inactive",
            label: 'International',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/directory/international.do`;
            },
          },
          {
            icon: "news-inactive",
            label: 'ACD',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/directory/ACD.do`;
            },
          },
          {
            icon: "news-inactive",
            label: 'SAGCD',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/directory/SAGCD.do`;
            },
          },
          {
            icon: "news-inactive",
            label: 'Association',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/directory/associations.do`;
            },
          },
          {
            icon: "news-inactive",
            label: 'Affiliates',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/directory/affiliations.do`;
            },
          },
          {
            icon: "ports-inactive",
            label: 'Ports',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/directory/ports.do`;
            },
          },
          {
            icon: "useful-links",
            label: 'Useful Links',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/directory/useful-links.do`;
            },
          }
        ]
      },
      {
        icon: "international-inactive",
        label: 'Resources',
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/resources.do`;
        },
      },
      {
        icon: "cool-gear-inactive",
        label: 'Cool Gear',
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/directory/cool-gear.do`;
        },
      },
      {
        icon: "notification-inactive",
        label: 'Notifications',
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/notifications.do`;
        },
      },
      {
        icon: "notification-inactive",
        label: 'Notification Log',
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/notifications/notifications-logs.do`;
        },
      },
      {
        icon: "history-inactive",
        label: 'Our History',
        id: 'ourHistory',
        command: (menu) => {
          this.collapsedItems.find(element => element.id === 'associationManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'pvwManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'companyManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'media').expanded = false;
          this.collapsedItems.find(element => element.id === 'saftyTraining').expanded = false;
          this.collapsedItems.find(element => element.id === 'directory').expanded = false;
          this.collapsedItems.find(element => element.id === 'dynamicGrids').expanded = false;
          this.collapsedItems.find(element => element.id === 'localManageMent').expanded = false;
          this.collapsedItems[0].expanded = false;
          this.shrinkItems[0].expanded = false;
          this.shrinkItems[0].icon = 'dashboard-active';
          this.setPreviousMenuInactive(menu,'forCollapsedMenu');
        },
        items: [
          {
            icon: "ports-inactive",
            label: 'Labour Story of the Month',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/our-history/monthly-labour-story.do`;
            },
          },
        ]
      },
      {
        icon: "info-inactive",
        label: 'About Us',
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/about-us.do`;
        },
      },
      {
        icon: "ports-inactive",
        label: 'Follow Us',
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/follow-us.do`;
        },
      },
      {
        icon: "info-inactive",
        label: `FAQ's`,
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/faq.do`;
        },
      },
      {
        icon: "history-inactive",
        label: `Dynamic Grids`,
        id: 'dynamicGrids',
        command: (menu) => {
          this.collapsedItems.find(element => element.id === 'associationManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'pvwManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'companyManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'media').expanded = false;
          this.collapsedItems.find(element => element.id === 'saftyTraining').expanded = false;
          this.collapsedItems.find(element => element.id === 'directory').expanded = false;
          this.collapsedItems.find(element => element.id === 'ourHistory').expanded = false;
          this.collapsedItems.find(element => element.id === 'localManageMent').expanded = false;
          this.collapsedItems[0].expanded = false;
          this.shrinkItems[0].expanded = false;
          this.shrinkItems[0].icon = 'dashboard-active';
          this.setPreviousMenuInactive(menu,'forCollapsedMenu');
        },
        items: [
          {
            icon: "info-inactive",
            label: `App Users Grid`,
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/user/appUserGrid.do`;
            },
          },
          {
            icon: "info-inactive",
            label: `ACD Grid`,
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/directory/ACDGrid.do`;
            },
          },
          {
            icon: "info-inactive",
            label: `SAGCD Grid`,
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/directory/SAGCDGrid.do`;
            },
          },
        ]
      },
      {
        icon: "wgma-inactive",
        label: `WGMA Users`,
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/wgma/user.do`;
        },
      },
      {
        icon: "wgma-inactive",
        label: `Jacksonville Users`,
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/jax/user.do`;
        },
      },
      {
        icon: "wgma-inactive",
        label: `ILA Users Import`,
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/ila/user.do`;
        },
      },
      {
        icon: "certifications",
        label: `Certifications`,
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/certificates.do`;
        },
      },
      {
        icon: "breaking-news",
        label: `Breaking News`,
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}secure/breaking-news.do`;
        },
      },
      {
        icon: "authorization-forms",
        label: `Authorization Forms`,
        command: () => {
          window.location.href = `${AppSettings.BASE_URL}/secure/sa/members.do`;
        },
      },

      {
        icon: "company-management",
        label: 'Company Management',
        expanded: false,
        id: 'companyManagement',
        command: (menu) => {
          this.collapsedItems.find(element => element.id === 'associationManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'pvwManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'media').expanded = false;
          this.collapsedItems.find(element => element.id === 'saftyTraining').expanded = false;
          this.collapsedItems.find(element => element.id === 'directory').expanded = false;
          this.collapsedItems.find(element => element.id === 'ourHistory').expanded = false;
          this.collapsedItems.find(element => element.id === 'dynamicGrids').expanded = false;
          this.collapsedItems.find(element => element.id === 'localManageMent').expanded = false;
          this.collapsedItems[0].expanded = false;
          this.shrinkItems[0].expanded = false;
          this.shrinkItems[0].icon = 'dashboard-active';
          this.setPreviousMenuInactive(menu,'forCollapsedMenu');
        },
        items: [
          {
            routerLink: ["company"],
            icon: "company-info",
            label: 'Company Information',
            command: () => {
            },
          },
          {
            routerLink: ["company-admin"],
            icon: "company-info",
            label: 'Company Admin',
            command: (menuP) => {
             menuP.expanded = false;
            },
          },

        ],
      },
      {
        icon: "company-management",
        label: 'Association Management',
        id: 'associationManagement',
        command: (menu) => {
          this.collapsedItems.find(element => element.id === 'companyManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'pvwManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'media').expanded = false;
          this.collapsedItems.find(element => element.id === 'saftyTraining').expanded = false;
          this.collapsedItems.find(element => element.id === 'directory').expanded = false;
          this.collapsedItems.find(element => element.id === 'ourHistory').expanded = false;
          this.collapsedItems.find(element => element.id === 'dynamicGrids').expanded = false;
          this.collapsedItems.find(element => element.id === 'localManageMent').expanded = false;
          this.collapsedItems[0].expanded = false;
          this.shrinkItems[0].expanded = false;
          this.shrinkItems[0].icon = 'dashboard-active';
          this.setPreviousMenuInactive(menu,'forCollapsedMenu');
        },
        items: [
          {
            routerLink: ["association-info"],
            icon: "company-info",
            label: 'Association Information',
            id: 'companyInfo',
            command: () => {
            },
          },
          {
            routerLink: ["association-admin"],
            icon: "company-info",
            label: 'Association Admin',
            command: () => {
            },
          }

        ],
      },

      {
        icon: "company-management",
        label: 'PV&W Management',
        id: 'pvwManagement',
        command: (menu) => {
          this.collapsedItems.find(element => element.id === 'companyManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'associationManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'media').expanded = false;
          this.collapsedItems.find(element => element.id === 'saftyTraining').expanded = false;
          this.collapsedItems.find(element => element.id === 'directory').expanded = false;
          this.collapsedItems.find(element => element.id === 'ourHistory').expanded = false;
          this.collapsedItems.find(element => element.id === 'dynamicGrids').expanded = false;
          this.collapsedItems.find(element => element.id === 'localManageMent').expanded = false;
          this.collapsedItems[0].expanded = false;
          this.shrinkItems[0].expanded = false;
          this.shrinkItems[0].icon = 'dashboard-active';
          this.setPreviousMenuInactive(menu,'forCollapsedMenu');
        },
        items: [
          {
            routerLink: ["pvw-info"],
            icon: "company-info",
            label: 'PV&W Information',
            id: 'companyInfo',
            command: () => {
            },
          },
          {
            routerLink: ["pvw-admin"],
            icon: "company-info",
            label: 'PV&W Admin',
            command: () => {
            },
          },

        ],
      },
      {
        icon: "history-inactive",
        label: 'Local Management',
        id: 'localManageMent',
        command: (menu) => {
          this.collapsedItems.find(element => element.id === 'companyManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'associationManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'companyManagement').expanded = false;
          this.collapsedItems.find(element => element.id === 'media').expanded = false;
          this.collapsedItems.find(element => element.id === 'saftyTraining').expanded = false;
          this.collapsedItems.find(element => element.id === 'directory').expanded = false;
          this.collapsedItems.find(element => element.id === 'ourHistory').expanded = false;
          this.collapsedItems.find(element => element.id === 'dynamicGrids').expanded = false;
          this.collapsedItems[0].expanded = false;
          this.shrinkItems[0].expanded = false;
          this.shrinkItems[0].icon = 'dashboard-active';
          this.setPreviousMenuInactive(menu,'forCollapsedMenu');
        },
        items: [
          {
            icon: "info-inactive",
            label: 'Local Information',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/homelocal/homelocal.do`;
            },
          },
          {
            icon: "app-users",
            label: 'Local Admin',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/homelocal/localadmin.do`;
            },
          },
          {
            icon: "events-inactive",
            label: 'Local Events',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/homelocal/events.do`;
            },
          },
          {
            icon: "events-inactive",
            label: 'Local Announcement',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/homelocal/announcements.do`;
            },
          },
          {
            icon: "local-upcoming-work-inactive",
            label: 'Local Upcoming Work',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/homelocal/upcoming-work.do`;
            },
          },
          {
            icon: "officials-inactive",
            label: 'Officials & Committees',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/homelocal/officials.do`;
            },
          },
          {
            icon: "governance-inactive",
            label: 'Governance & Forms',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/homelocal/documents.do`;
            },
          },
          {
            icon: "cool-gear-inactive",
            label: 'Settings',
            command: () => {
              window.location.href = `${AppSettings.BASE_URL}/secure/homelocal/settings.do`;
            },
          },


        ],
      },
      {
        icon: "logout",
        label: `Logout`,
        id: 'logout',
        command: () => {
          this.logout();
        },
      },

    ];


  }


  //SHRINK MENU
  public initShrinkMenuItems() {
    this.shrinkItems = [
      {
        icon: "dashboard-inactive",
        label: null,
        expanded: true
      },
      {
        icon: "onboarding-members-inactive",
        label: null,
        visible:this.onBoardingProcess === AppSettings.ACCESS_TO_ONBOARDING,

      },

      {
        icon: "app-users",
        label: null
      },
      {
        icon: "news-inactive",
        label: null
      },
      {
        icon: "breaking-news",
        label: null
      },
      {
        icon: "events-inactive",
        label: null
      },
      {
        icon: "media-inactive",
        label: null
      },
      {
        icon: "info-inactive",
        label: null
      },
      {
        icon: "directory-inactive",
        label: null
      },
      {
        icon: "international-inactive",
        label: null
      },
      {
        icon: "cool-gear-inactive",
        label: null
      },
      {
        icon: "notification-inactive",
        label: null
      },
      {
        icon: "notification-inactive",
        label: null
      },
      {
        icon: "history-inactive",
        label: null,
        id: 'ourHistory'
      },
      {
        icon: "info-inactive",
        label: null
      },
      {
        icon: "ports-inactive",
        label: null
      },
      {
        icon: "info-inactive",
        label: null
      },
      {
        icon: "history-inactive",
        label: null
      },
      {
        icon: "wgma-inactive",
        label: null
      },
      {
        icon: "wgma-inactive",
        label: null
      },
      {
        icon: "wgma-inactive",
        label: null
      },
      {
        icon: "certifications",
        label: null
      },
      {
        icon: "breaking-news",
        label: null
      },
      {
        icon: "authorization-forms",
        label: null
      },
      {
        icon: "company-management",
        label: null
      },
      {
        icon: "logout",
        label: null
      }
    ];
  }

  public shrinkMenu(): void {
    this.sidebarService.sideSubject.next('shrink-menu');
  }

  public collapseMenu(): void {
    this.sidebarService.sideSubject.next('expand');
  }

  logout() {
    this.userCommonService.logout().subscribe((result) => {
      if (result?.messageType === "ERROR") {
        this.ms.add({
          severity: "error",
          summary: result.messageType,
          detail: result.message,
        });
      } else {
        this.ms.add({
          severity: "success",
          summary: result.messageType,
          detail: result.message,
        });
        this.storageService.removeAll();
        this.storageService.removeAllCookies();
        this.router.navigate(["/signin"]);
      }
    });
  }

  setPreviousMenuInactive(event, forMenu): void {
      this.collapsedItems.forEach((element) => {
        if (event.id !==  element.id) {
         switch(element.id) {
           case 'dashboard': element.icon = 'dashboard-active'; break;
         }
        }
       });

       this.shrinkItems.forEach((element) => {
        if (event.id !==  element.id) {
         switch(element.id) {
           case 'dashboard': element.icon = 'dashboard-active'; break;
         }
        }
       });
  }

}
