import { Injectable } from '@angular/core';
import { RestApiService } from '../services/rest-api.service';
import { Observable, Subject } from 'rxjs';
import { MessageService } from 'primeng/api';


@Injectable()
export class UserCommonService {
  showSwitchLocal = new Subject<boolean>();

  constructor(private restApiService: RestApiService,    private messageService: MessageService
    ) {}
  // signOut(): Observable<any> {
  //   return this.restApiService.delete('/secure/signout', 'page-center');
  // }

  showMessage(severity: any, summary: any, detail: any) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail
    });
  }

  signIn(d, data): Observable<any> {
    // return this.restApiService.post(d, '/login', data, 'page-center');
    return this.restApiService.post(d, '/api/users/session.json', data, 'page-center');
  }

  // passwordChange(data): Observable<any> {
  //   return this.restApiService.put('/secure/users/profile/password', data, 'page-center');
  // }

  setPassword(data): Observable<any> {
    return this.restApiService.post('password', '/api/users/set-password', data, 'page-center');
  }

  getUserProfile(): Observable<any> {
    return this.restApiService.get('profile', '/api/secure/users/profile', 'page-center');
  }

  forgotPassword(data): Observable<any> {
    return this.restApiService.post('password', '/api/users/forgot-password.json', data, 'page-center');
  }

  resetPassword(data, token): Observable<any> {
    return this.restApiService.post('password', `/api/users/reset-password.json?token=${token}`, data, 'page-center');
  }

  public getLocalIdList(districtName: string): Observable<any> {
    return this.restApiService.get('get district', `/ajax/secure/homelocal/announcements/local/list.json?districtName=${districtName}`);
  }

 public swicthLocal(primaryLocal , data): Observable<any> {
    return this.restApiService.post('password', `/api/mylocal/switch-local.json?primaryLocal=${primaryLocal}` , data);
  }

  public logout():Observable<any>{
    return this.restApiService.delete('Logout Session', '/api/users/session.json')
  }
  public getCountOfWorkerData(data): Observable<any> {
    return this.restApiService.post('get worker data count', '/api/secure/homelocal/members/export-form-job-count.json', data, 'page-center');
  }

  public exportWorkerData(data): Observable<any> {
    return this.restApiService.post('export worker data', '/api/secure/homelocal/members/add-export-form-job.json', data, 'page-center');
  }

}

