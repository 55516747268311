import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { MessageService } from "primeng/api";

import { UiService } from "./../../services/ui.service";
import { GridService } from "./grid.service";
import { GridModel } from "./grid-model";
import { LoaderService } from "../loader/loader.service";
import { LazyLoadEvent } from "primeng/api";
import { Router } from "@angular/router";
import { UserCommonService } from "../../services/user-common.service";
import { Table } from "primeng/table";
import { SimpleObject } from "../../models/simple-object";
import { StorageService } from "../../services/storage.service";
import { AppSettings } from "../../app.settings";
import { NotificationService } from "../../../notifications/notification.service";
@Component({
  selector: "app-mi-grid-v2",
  providers: [GridService],
  templateUrl: "./mi-grid.component.html",
  styleUrls: ["./mi-grid.component.scss"],
})
export class MiGridComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  rowData: any[] = [];
  @Input() search = "";
  gridUrlParams = "";
  @Output() rowClickedEvent = new EventEmitter<any>();
  @Output() actionBtnClickedEvent = new EventEmitter<any>();
  @Output() getGridReference: EventEmitter<any> = new EventEmitter();
  @Output() clearAllEvent = new EventEmitter<any>();
  @Output() isrecord = new EventEmitter<any>();
  @Input() url: any = "";
  @Input() searchBar: string;
  @Input() exportButton: string;
  @Input() rowSelection = "single";
  @Input() printUrl: any = "";
  @Input() exportUrl: any = "";
  @Input() columnDefs: any[] = [];
  @Input() actionButtonsDefs: any[] = [];
  @Input() pagination: any = true;
  @Input() paginationPageSize: any = 10;
  @Input() paginationStartPage: any = 0;
  @Input() defaultSortField = "";
  @Input() divClass = "";
  @Input() optionsForDropDownOne: any[] = [];
  @Input() selectedStatus = "A";
  @Input() buttonLabel: string = null;
  @Input() searchbarClass = "p-col-3";
  @Input() statusClass = "p-col-3";
  @Input() actionButtonClass = "p-col-3";
  @Input() clearButtonClass = "p-col-3";
  @Input() rowsPerPage = [10, 25, 50];
  @Input() isStatusDropdown = false;
  @Input() isAddMember = false;
  @Input() isClear = false;
  @Input() showExportBtn = false;
  @Input() exportButtonClass = "p-col-4";
  @Input() searchPlaceholder = "Search";
  @Input() exportBtnLabel = "Export To Excel";

  @Input() showActionBtn = true;
  @Input() showSearchAndActionBtn = true;
  @Output() exportBtnClicked = new EventEmitter<any>();
  @Output() emitTotalRecords = new EventEmitter<any>();

  enableResizer = "false";
  @Input() overlayLoadingTemplate: any =
    '<span class="ag-overlay-loading-center" "style=background:red">' +
    "Please wait while your rows are loading</span>";
  datasource = [];
  model: GridModel;
  totalRecords = 0;
  sortF = "";
  sortO = "";
  offset = 0;
  setRowLength = 10;
  searchText = "";
  @Output() searchEnterText = new EventEmitter<any>();
  @Input() status = "A";
  selectedRecords: any = [];
  @ViewChild(MiGridComponent) gridRef: MiGridComponent;
  @ViewChild("resetPage", { static: true }) resetPage: Table;

  @Input() exportData = false;
  showExportData: boolean = false;
  endDateIsNotSelected = false;
  rangeDates: Date[];
  reportSearchFields = [];
  toDate: Date;
  @ViewChild('myCalendar') datePicker;
  workerSearch:any = '';
  formTypeList: Array<SimpleObject> = [];
  formType: any = '';
  showExport: boolean = false;
  selectedState: any = null;
  @Input() stateList: Array<SimpleObject> = [];
  activeLocal: any = '';
  userRole: number | null = null;
  isShowExport: boolean = false;
  membersList: Array<SimpleObject> = [];
  selectedMembers: any;
  responseMessage: any = '';
  responseErrorMessage: any = '';
  resetFilter: boolean = false;
  userId: any = '';
  isDateError: boolean = false;
  exportCount: any = 0;

  constructor(
    private elm: ElementRef,
    private loaderService: LoaderService,
    private uiService: UiService,
    private gridService: GridService,
    private router: Router,
    private commonService: UserCommonService,
    private ms: MessageService,
    private storageService: StorageService,
    private notificationService: NotificationService
  ) {
    this.toDate = new Date();
    this.formTypeList = AppSettings.FORM_TYPE_LIST;
  }

  ngOnInit() {
    this.userRole = JSON.parse(
      this.storageService.getItem(AppSettings.USER_ROLE)
    );    
    this.userId = this.storageService.getItem(AppSettings.USERID);
    
    this.isShowExport = false;
    
    console.log("userRole ", this.userRole);
    
    if(this.userRole == 3 || this.userRole == 4 || this.userRole == 5  || this.userRole == 6) {
      this.isShowExport = true;
    }

    this.getMembersInfo();

    this.activeLocal = JSON.parse(
      this.storageService.getItem(AppSettings.ACTIVE_LOCAL)
    );
    this.subscription = this.uiService.sidebarToggledEvent.subscribe(
      (state: string) => {
        this.refreshList();
      }
    );
  }

  onResize(event: any) {
    this.refreshList();
  }

  refreshList() {
    this.fetchAndLoadData();
  }

  fetchAndLoadData() {
    //  const that = this;
    let params =
      "search=" +
      this.searchText +
      "&recordStatus=" +
      this.status +
      "&offset=" +
      this.offset +
      "&limit=" +
      this.setRowLength;

    params += "&sortColumn=";
    params += this.sortF;
    params += "&sortType=";
    params += this.sortO;
    let buildUrl = this.url;
    if (this.url.lastIndexOf("?") < 0) {
      buildUrl += "?";
    } else {
      buildUrl += "&";
    }
    buildUrl += params;
    this.gridService.loadGridData(buildUrl).subscribe((result) => {
      this.isrecord.emit(result.records.length);
      this.loaderService.hide();
      const model = <GridModel>result;
      if (result !== undefined) {
        this.totalRecords = model.pagination.totalRecords;
        this.emitTotalRecords.emit(this.totalRecords);
        this.datasource = [];
        model.records.forEach((e) => {
          this.columnDefs.forEach((c) => {
            if (c && c.cellRenderer && !c.htmlRenderer) {
              e[c.field] = c.cellRenderer(e[c.field]);
            }
            if (c && c.htmlRenderer) {
              e[c.field] = c.htmlRenderer(e);
            }
          });

          this.datasource.push(e);
        });
      }
    });
  }

  public onQuickFilterChanged($event) {
      this.applySearch(this.search);
  }

  updateUrl(url: string) {
    this.url = url;
    this.refreshList();
  }

  loadDatasourceLazy(event: LazyLoadEvent) {
    this.sortF = this.defaultSortField;
    this.offset = event.first;
    this.setRowLength = event.rows;
    if (event.sortField !== undefined) {
      this.sortF = event.sortField;
    }
    this.sortO = event.sortOrder > 0 ? "asc" : "desc";
    this.fetchAndLoadData();
  }

  onRowSelect(event) {
    this.rowClickedEvent.emit(event);
  }

  onRowUnselect(event) {
    this.rowClickedEvent.emit(event);

  }

  public showSendEmailPopup(): void {}

  actionBtnClick(data: any, action: string) {
    const obj = { row: data, class: action };
    this.actionBtnClickedEvent.emit(obj);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getSelectedRecords() {
    return this.selectedRecords;
  }

  applySearch(searchText: string) {
    this.searchEnterText.emit(this.searchText);
    this.offset = 0;
    this.searchText = searchText;
    this.fetchAndLoadData();
    if (this.searchText === "") {
      this.resetPage.reset();
    }
  }

  statusFilter(event: any) {
    this.status = event.value;
    this.fetchAndLoadData();
  }

  exportGrid(event: any) {
    this.exportBtnClicked.emit();
  }

  clearAll(): void {
    this.searchText = "";
    this.search = null;
    this.status = "";
    this.clearAllEvent.emit(true);
    this.fetchAndLoadData();
  }

  exportDialog() {
    this.showExportData = true;
  }

  resetDateRange() {
    this.rangeDates = null;
    this.reportSearchFields = [];
    this.showExport = false;
    this.resetFilter = false;
    this.responseErrorMessage = this.responseMessage = '';
  }

  onDateRangeClose(event) {
    const startDate = new Date(this.rangeDates[0]);
    startDate.setHours(0, 0, 0);
    
    if (this.rangeDates[1] === null) {
      this.rangeDates.splice(1);
      this.endDateIsNotSelected = true;

    } else {
      const endDate = new Date(this.rangeDates[1]);
      endDate.setHours(23, 59, 59);
      this.endDateIsNotSelected = false;

      this.reportSearchFields = this.reportSearchFields.filter((field) =>
        !['signedDateRangeFrom', 'signedDateRangeTo'].includes(field.fieldName)
      );
      this.reportSearchFields.push({
        'signedDateRangeFrom' : startDate.getTime(),
        'signedDateRangeTo' : endDate.getTime(),
      });

      this.datePicker.overlayVisible = false;

    }
  }

  onDateSelection(event) {
    this.rangeDates = [];
    const startDate = new Date(event.getFullYear(), event.getMonth());
    const endDate = new Date(event.getFullYear(), event.getMonth());
    this.rangeDates.push(startDate, endDate);
  }

  exportWorkerData() {
    const workerIds = this.selectedMembers 
    ? this.selectedMembers
        .map(item => item.id)
        .filter(id => id)   // Filter out falsy values (undefined, null, empty strings, etc.)
        .join(",")
    : '';

    this.loaderService.show("success");
    const signedDateRangeFrom = this.reportSearchFields && this.reportSearchFields.length > 0 
    ? this.reportSearchFields[0]['signedDateRangeFrom'] 
    : 0;
    const signedDateRangeTo = this.reportSearchFields && this.reportSearchFields.length > 0 
    ? this.reportSearchFields[0]['signedDateRangeTo'] 
    : 0;
    if(this.userRole==3) {
      this.selectedState = '';
    }
    const reqPayload = {"status":"PROCESS","state":this.selectedState,"local":this.activeLocal,"formType":this.formType,"signedDateRangeFrom":signedDateRangeFrom,"signedDateRangeTo":signedDateRangeTo, "workerSearch":workerIds, "createdBy":this.userId.replace(/"/g, '').trim()};
    this.commonService.exportWorkerData(reqPayload).subscribe((result) => {
      if(result) {
        if(result.messageType == 'SUCCESS'){
          this.showExport = true;
          this.ms.add({
            severity: "success",
            summary: result.messageType,
            detail: `Your export (${this.exportCount}) had been processed successfully. You will receive an email shortly with the details.`,
          });
          this.loaderService.hide();
          this.showExportData = false;
          this.resetFilters();
        } else {
          this.showExport = false;
          this.ms.add({
            severity: "error",
            summary: result.messageType,
            detail: result.message,
          });
          this.loaderService.hide();
        }
      }
      
    });
  }

  onDialogClose() {
    this.showExport = this.isDateError = this.endDateIsNotSelected = this.resetFilter = this.showExportData = false;
    this.reportSearchFields = [];
    this.rangeDates = [];
    this.workerSearch = '';
    this.formType = '';
    this.selectedState = '';
    this.selectedMembers = null;
    this.responseErrorMessage = '';
    this.responseMessage = '';
  }

  resetFilters() {
    this.reportSearchFields = [];
    this.rangeDates = [];
    this.workerSearch = '';
    this.formType = '';
    this.selectedState = '';
    this.resetFilter = false;
    this.selectedMembers = null;
    this.isDateError = false;
    this.responseErrorMessage = '';
    this.responseMessage = '';
    this.showExport = false;
  }

  applyFiter() {
    console.log("reportSearchFields", this.reportSearchFields);
    console.log("selectedMembers", this.selectedMembers);

    const signedDateRangeFrom = this.reportSearchFields && this.reportSearchFields.length > 0 
      ? this.reportSearchFields[0]['signedDateRangeFrom'] 
      : 0;
    const signedDateRangeTo = this.reportSearchFields && this.reportSearchFields.length > 0 
    ? this.reportSearchFields[0]['signedDateRangeTo'] 
    : 0;
    const workerIds = this.selectedMembers 
    ? this.selectedMembers
        .map(item => item.id)
        .filter(id => id)   // Filter out falsy values (undefined, null, empty strings, etc.)
        .join(",")
    : '';
    
    if (!this.endDateIsNotSelected) {
      this.isDateError = false;
      this.loaderService.show("success");

      if(this.userRole==3) {
        this.selectedState = '';
      }
      const reqPayload = { "status": "PROCESS", "state": this.selectedState, "local": this.activeLocal, "formType": this.formType, "signedDateRangeFrom": signedDateRangeFrom, "signedDateRangeTo": signedDateRangeTo, "workerSearch": workerIds, "createdBy":this.userId.replace(/"/g, '').trim() };
      
      this.commonService.getCountOfWorkerData(reqPayload).subscribe((result) => {
        if (result) {
          if (result.exportJobCount == 0) {
            this.exportCount = result.exportJobCount;
            this.showExport = false;
            this.responseErrorMessage = 'There are no workers found as per the applied filters';
            this.resetFilter = true;
            this.responseMessage = '';
          } else {
            this.showExport = true;
            this.resetFilter = false;
            this.responseErrorMessage = '';
            this.exportCount = result.exportJobCount;
            if (result.exportJobCount < 100) {
              this.responseMessage = "There are " + result.exportJobCount + " workers ready for export.";
            } else {
              this.responseMessage = `There are ${result.exportJobCount} workers ready for export. <br /><br /> <span class="secondary-message">You've selected a large number of workers. This may take up to 30 minutes to complete. You'll receive an email when it's done.</span>`;
            }
            
          }
        }

      });
    } else {
      this.showExport = false;
      this.isDateError = true;
      setTimeout(()=> {
        this.isDateError = false;
      }, 2000);
    }
    
  }

  onStateChange(state: any): void {
    this.selectedState = state.value;
  }

  onInputChange(event: any): void {
    this.showExport = false;
    this.resetFilter = false;
    this.responseErrorMessage = this.responseMessage = '';
  }

  getMembersInfo() {
    this.membersList = [];
    this.notificationService.getMembersInfo().subscribe((results) => {
      results.records.forEach((element) => {
        const members = {
          id: element.workerId,
          value:
            element.firstName +
            " " +
            element.lastName +
            " (" +
            element.workerId +
            ")",
        };
        this.membersList.push(members);
      });
    });
  }

  filterMembers(members: any) {
    const member = members.filter;
    const limit = 200;
    //this.membersList = [];
    this.notificationService.MembersInfo(member, limit).subscribe((results) => {
      
      results.records.forEach((element) => {
        const isAlreadyExists = this.membersList.some(
          (member) => member.id === element.workerId
        );
        if (!isAlreadyExists) {
          const members = {
            id: element.workerId,
            value:
              element.firstName +
              " " +
              element.lastName +
              " (" +
              element.workerId +
              ")",
          };
          this.membersList.push(members);
        }
      });
    });
    
  }
}
